import {Component, OnInit, ViewChild} from '@angular/core';
import {Employee} from '../admin/employee/employee';
import {ApiService} from 'src/app/admin/api.service';
import {UtilitiesService} from '../services/utilities.service';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Subscription} from 'rxjs';
import * as globals from 'src/app/globals';
import {GlobalBehavioursService} from '../services/global-behaviours.service';
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './user-list.component.html'
})

export class UserListComponent implements OnInit {

  constructor(public auth: AuthService,
              private apiService: ApiService,
              public globalBehavioursService: GlobalBehavioursService,
              private utilitiesService: UtilitiesService) {
  }
  title = 'payroll-system';

  employees: MatTableDataSource<Employee>;
  orphanUsers: MatTableDataSource<any>;
  displayedColumns: string[] = ['firstName', 'lastName', 'emailAddress', 'username', 'manage'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
  subscription: Subscription;

  // Create de default constructor if possible.
  employee: any;//new Employee('', '', '', '', '', '', '', 0, 0, 0, 0, '', '', '', '', '', '', '', '', '', '', '');

  updateMode = false;

  isServiceAvailable$ = this.globalBehavioursService.isServiceAvailable$;

  restrictedRole = 'ROLE_RESTRICTED';

  ngOnInit() {
    this.getEmployees();
    this.getOrphanUsers();
  }

  getEmployees(): any {
    //this.employeeService.getAll().subscribe(
    this.apiService.getAll(globals.EMPLOYEE_USER_ENDPOINT).subscribe(
      (res: Employee[]) => {
        this.employees = new MatTableDataSource(res);
        this.employees.paginator = this.paginator;
        this.employees.sort = this.sort;
      }
    );
  }

  getOrphanUsers(): any {
    this.apiService.getAll(globals.USER_ORPHAN_ENDPOINT).subscribe(
      (res: any[]) => {
        this.orphanUsers = new MatTableDataSource(res);
        this.employees.paginator = this.paginator;
        this.employees.sort = this.sort;
      }
    );
  }

  getEmployee(id): void {//WHERE IS THIS USED? IF NOWHERE, DELETE.
    //this.employeeService.getEmployee(id).subscribe(
    this.apiService.getById(globals.EMPLOYEE_ENDPOINT, id).subscribe(
      (res: Employee) => {
        this.employee = res;
      }
    );
  }

  encryptParams(EmpId, userId?): string{
    if (EmpId && userId) {
      return this.utilitiesService.Encrypt(userId + ' ' + EmpId);
    } else if (EmpId && !userId) {
      return this.utilitiesService.Encrypt(EmpId);
    } else return null;
  }

  getUserObject(empId, userId?) {
    if (empId && userId) {
      return {empId: this.encryptParams(empId), userId: this.encryptParams(userId)};
    } else if (empId && !userId) {
      return {empId: this.encryptParams(empId)};
    }
  }

  getUserIdObject(userId) {
    return {userId: this.encryptParams(userId)};
  }

  applyFilter(filterValue: string) {
    this.employees.filter = filterValue.trim().toLowerCase();

    if (this.employees.paginator) {
      this.employees.paginator.firstPage();
    }
  }

  applyOrphanFilter(filterValue: string) {
    this.orphanUsers.filter = filterValue.trim().toLowerCase();

    if (this.orphanUsers.paginator) {
      this.orphanUsers.paginator.firstPage();
    }
  }

  hasRestrictedRole() {
    return this.auth.hasRole(this.restrictedRole);
  }
}
